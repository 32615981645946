.container {
  margin-top: var(--spacing-bottom);
  margin-bottom: var(--spacing-bottom);
  background-color: var(--page-theme-background-color, var(--color-bg-shade));
}

.header {
  padding-right: var(--spacing-sides);
  padding-left: var(--spacing-sides);
}

.header-inner {
  margin-right: auto;
  margin-left: auto;
  max-width: var(--max-width-base);
}

.title {
  margin-right: auto;
  margin-bottom: 54px;
  margin-left: auto;
  max-width: 400px; /* magic number, this was 60% previously */
  color: var(--page-theme-contrast-color);
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-24px);
  font-family: var(--font-family-heading);
  text-align: center;

  @media (width >= 650px) {
    max-width: initial;
    font-size: var(--font-size-36px);
    text-align: initial;
  }
}

.inner {
  position: relative;
  background-color: var(--color-surface-solid-dark-darker);
  padding-top: calc(16 / 10 * 100%);
  height: 0;
  overflow: hidden;

  @media (width >= 650px) {
    padding-top: calc(4 / 3 * 100%);
  }

  @media (width >= 1000px) {
    padding-top: calc(10 / 16 * 100%);
  }

  @media (width >= 1440px) {
    padding-top: 752px;
  }
}

.navigation-overlay {
  position: absolute;
  top: 90px;
  bottom: 100px;
  z-index: 2;

  @media (width >= 768px) {
    display: none;
  }
}

.navigation-overlay--left {
  right: 50%;
  left: 0;
}

.navigation-overlay--right {
  right: 0;
  left: 50%;
}

.image,
.video {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
}

.timeline-wrapper {
  -ms-overflow-style: none;
  position: absolute;
  top: 40px;
  left: var(--spacing-sides);
  z-index: 2;
  margin: 0;
  width: 100%;
  max-width: var(--max-width-base);
  height: 100px;
  overflow: hidden;

  @media (width >= 650px) {
    top: 50px;
  }

  @media (width >= 1440px) {
    left: 50%;
    transform: translateX(-50%);
  }
}

.timeline {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  transition: transform linear;
  width: 100%;
  overflow-x: visible;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.timeline-item {
  display: block;
  flex-shrink: 0;
  opacity: 0.2;
  transition: opacity 300ms;
  cursor: pointer;
  padding-right: 16px;
  width: 115px;
  text-decoration: none;

  @media (width >= 768px) {
    width: 160px;
  }

  @media (width >= 1024px) {
    width: 180px;
  }
}

@media (width >= 1000px) {
  .timeline-item:hover {
    opacity: 1;
  }
}

.timeline-item--active {
  opacity: 1;
}

.timeline-item > * {
  pointer-events: none;
}

.timeline-progress-bar {
  margin-bottom: 12px;
  border-radius: 1px;
  background-color: var(--color-text-light);
  height: 2px;
}

.timeline-progress-bar-meter {
  background-color: var(--color-primary);
  width: 0;
  height: 100%;

  .timeline-item--active & {
    animation-duration: 4s;
    animation-timing-function: linear;
    animation-name: progress;
  }
}

.timeline-tag {
  display: none;
  color: var(--color-text-light);
  font-weight: bold;
  font-size: var(--font-size-14px);
  line-height: 1.43;

  @media (width >= 1000px) {
    display: block;
  }
}

.content {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  padding-right: var(--spacing-sides);
  padding-bottom: 36px;
  padding-left: var(--spacing-sides);
  width: 100%;
  height: 100%;

  @media (width >= 1000px) {
    padding-bottom: var(--spacing-sides);
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(
      to bottom,
      rgba(0, 15, 30, 0.7),
      rgba(0, 15, 30, 0)
    );
    width: 100%;
    height: 25%;
    pointer-events: none;
    content: "";
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(
      to top,
      rgba(0, 15, 30, 0.7),
      rgba(0, 15, 30, 0)
    );
    width: 100%;
    height: 100%;
    pointer-events: none;
    content: "";
  }
}

.content--hidden {
  display: none;
}

.content-text {
  position: relative;
  z-index: 2;
}

.content-title {
  position: relative;
  z-index: 2;
  margin-bottom: 36px;
  color: var(--color-text-light);
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-36px);
  line-height: 1.19;
  font-family: var(--font-family-heading);
  text-shadow:
    0 0 20px rgba(0, 15, 30, 0.2),
    0 0 3px rgba(0, 15, 30, 0.3);
  text-transform: none;

  @media (width >= 1000px) {
    max-width: 760px;
    font-size: var(--font-size-68px);
  }
}

.content-tag {
  display: block;
  margin-bottom: 10px;
  margin-left: 4px;
  color: var(--color-text-light);
  font-weight: 700;
  font-size: var(--font-size-14px);
  text-transform: uppercase;
}

.content-button {
  --cosmos-button-min-width: var(--button-min-width);
}

@keyframes progress {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}
